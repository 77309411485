import { createSlice } from "@reduxjs/toolkit";
import {
  addTokenApi,
  getUserConfigApi,
  getUserTokensApi,
} from "src/Service/authService";
import { tokenStoreAction } from "./store";

const initialState = {
  tokenData: {
    data: [],
    isDataRecevied: false,
  },
  reRunComponent: {
    isDataChanged: true,
    queryParam: "",
  },
  configData: {
    data: [],
    isDataRecevied: false,
  },
  reRunConfigComponent: {
    isDataChanged: true,
    queryParam: "",
  },
  message: {
    msg: [],
    color: "",
    status: false,
    dataReceived: false,
  },
  modalData: {
    isModalOpen: false,
    modalHeading: "Generate A Token to Verify the Store",
    modalActionButton: "Copy and Save",
    sendRequest: false,
    color: "primary",
    showButton: false,
    disableCloseBtn: false,
    disabled: false,
  },
};

const tokenSlice = createSlice({
  name: "userToken",
  initialState,
  reducers: {
    getTokenResponse(state, action) {
      state.tokenData = {
        ...state.tokenData,
        isDataRecevied: true,
        data: action.payload.data,
      };
    },
    getConfigResponse(state, action) {
      state.configData = {
        ...state.configData,
        isDataRecevied: true,
        data: action.payload.data,
      };
    },
    updateParam(state, action) {
      state.reRunComponent = {
        ...state.reRunComponent,
        queryParam: action.payload.param,
      };
    },
    updateConfigParam(state, action) {
      state.reRunConfigComponent = {
        ...state.reRunConfigComponent,
        queryParam: action.payload.param,
      };
    },
    openModal(state, action) {
      state.modalData = {
        ...state.modalData,
        isModalOpen: true,
      };
      //  console.log(action.payload.data.config_form);
    },
    sendAddTokenRequest(state) {
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: false,
      };
      state.modalData = {
        ...state.modalData,
        sendRequest: true,
      };
    },
    showAddTokenButton(state) {
      state.modalData = {
        ...state.modalData,
        showButton: true,
      };
    },
    getResponse(state, action) {
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: true,
      };
      state.modalData = {
        ...state.modalData,
        sendRequest: false,
        modalActionButton: "Copied and Saved",
        disabled: true,
        disableCloseBtn: true,
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
        status: action.payload.status,
        dataReceived: true,
      };
    },
    closeModal(state) {
      state.modalData = {
        ...state.modalData,
        isModalOpen: false,
        modalActionButton: "Copy and Save",
        showButton: false,
        disabled: false,
      };
      state.message = {
        msg: [],
        color: "",
        status: false,
        dataReceived: false,
      };
    },
  },
});

export default tokenSlice;

export const getTokens = (data) => {
  return (dispatch) => {
    getUserTokensApi(data.row, data.param)
      .then((response) => {
        dispatch(
          tokenStoreAction.getTokenResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const getClientConfig = (data) => {
  return (dispatch) => {
    getUserConfigApi(data.row, data.param)
      .then((response) => {
        dispatch(
          tokenStoreAction.getConfigResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const sendTokenRequest = (data) => {
  return (dispatch) => {
    dispatch(tokenStoreAction.sendAddTokenRequest());
    addTokenApi(data)
      .then((response) => {
        dispatch(
          tokenStoreAction.getResponse({
            msg: [response.data.data.msg],
            color: "success",
            status: true,
          })
        );
        setTimeout(() => {
          dispatch(tokenStoreAction.closeModal());
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
