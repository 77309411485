import { createSlice } from "@reduxjs/toolkit";
import API from "src/axios/axios";
import { addProviderApi, UpdateProviderApi } from "src/Service/authService";
import { providerStoreAction } from "./store";

const initialState = {
  providerData: [],
  isDataChanged: false,
  reRunComponent: {
    changed: true,
    queryParam: "",
  },

  addProviderModalData: {
    isModalOpen: false,
    modalHeading: "Add a IPG Provider",
    modalActionButton: "Add",
    sendRequest: false,
    color: "primary",
    keyList: [],
  },
  message: {
    msg: [],
    color: "",
    status: false,
    dataReceived: false,
  },
  actionModalData: {
    isModalOpen: false,
    modalHeading: "",
    modalActionButton: "",
    sendRequest: false,
    color: "",
    providerData: "",
    providerId: 0,
    keyList: [],
  },
};

const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {
    updateParam(state, action) {
      state.reRunComponent = {
        ...state.reRunComponent,
        queryParam: action.payload.param,
      };
    },
    getData(state, action) {
      state.providerData = action.payload.val;
      state.isDataChanged = true;
    },
    openAddModal(state) {
      state.addProviderModalData = {
        ...state.addProviderModalData,
        isModalOpen: true,
      };
    },
    addKeyToList(state, action) {
      const list = [...state.addProviderModalData.keyList, action.payload.data];
      state.addProviderModalData = {
        ...state.addProviderModalData,
        keyList: list,
      };
    },
    ActionAddKeyToList(state, action) {
      const list = [...state.actionModalData.keyList, action.payload.data];
      state.actionModalData = {
        ...state.actionModalData,
        keyList: list,
      };
    },

    updateKeyToList(state, action) {
      const id = action.payload.listData.id;
      const data = action.payload.listData.list;
      const list = [...state.addProviderModalData.keyList];
      list[id] = data;
      state.addProviderModalData = {
        ...state.addProviderModalData,
        keyList: list,
      };
    },
    actionUpdateKeyToList(state, action) {
      const id = action.payload.listData.id;
      const data = action.payload.listData.list;
      const list = [...state.actionModalData.keyList];
      list[id] = data;
      state.actionModalData = {
        ...state.actionModalData,
        keyList: list,
      };
    },

    deleteKeyFromList(state, action) {
      const id = action.payload.id;
      const list = [...state.addProviderModalData.keyList];
      list.splice(id, 1);
      state.addProviderModalData = {
        ...state.addProviderModalData,
        keyList: list,
      };
    },
    actionDeleteKeyFromList(state, action) {
      const id = action.payload.id;
      const list = [...state.actionModalData.keyList];
      list.splice(id, 1);
      state.actionModalData = {
        ...state.actionModalData,
        keyList: list,
      };
    },
    sendRequest(state) {
      state.reRunComponent = false;
      state.addProviderModalData = {
        ...state.addProviderModalData,
        sendRequest: true,
      };
      state.reRunComponent = {
        changed: false,
        queryParam: "",
      };
    },
    sendActionRequest(state) {
      state.reRunComponent = false;
      state.actionModalData = {
        ...state.actionModalData,
        sendRequest: true,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        changed: false,
      };
    },
    getResponse(state, action) {
      state.reRunComponent = true;
      state.addProviderModalData = {
        ...state.addProviderModalData,
        sendRequest: false,
        keyList: [],
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
        status: action.payload.status,
        dataReceived: true,
      };
      state.reRunComponent = {
        changed: true,
        queryParam: "",
      };
    },
    getActionResponse(state, action) {
      state.reRunComponent = true;
      state.actionModalData = {
        ...state.actionModalData,
        sendRequest: false,
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
        status: action.payload.status,
        dataReceived: true,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        changed: true,
      };
    },

    closeModal(state) {
      state.addProviderModalData = {
        ...state.addProviderModalData,
        isModalOpen: false,
      };
      state.actionModalData = {
        ...state.actionModalData,
        isModalOpen: false,
      };
      state.message = {
        msg: [],
        color: "",
        status: false,
        dataReceived: false,
      };
    },
    openActionModal(state, action) {
      state.actionModalData = {
        ...state.actionModalData,
        isModalOpen: true,
        providerId: action.payload.id,
        modalHeading: action.payload.heading,
        modalActionButton: action.payload.action,
        sendRequest: false,
        color: action.payload.color,
        providerData: action.payload.data,
        keyList: action.payload.data.config_form,
      };
      //  console.log(action.payload.data.config_form);
    },
  },
});

export default providerSlice;

export const getProviderData = (data) => {
  return (dispatch) => {
    let url = `provider/get/${data.row}`;
    if (data.params) {
      url = `provider/get/${data.row}?${data.params}`;
    }
    API.get(url)
      .then((response) => {
        if (response.data.http_status === 200) {
          dispatch(providerStoreAction.getData({ val: response.data.data }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const addProviderData = (data) => {
  return (dispatch) => {
    dispatch(providerStoreAction.sendRequest());
    addProviderApi(data)
      .then((response) => {
        dispatch(
          providerStoreAction.getResponse({
            msg: [response.data.data.msg],
            color: "success",
            status: true,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const sendActionRequest = (data) => {
  return (dispatch) => {
    dispatch(providerStoreAction.sendActionRequest());
    UpdateProviderApi(data)
      .then((response) => {
        dispatch(
          providerStoreAction.getActionResponse({
            msg: [response.data.data.msg],
            color: "success",
            status: true,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
