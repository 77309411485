import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import "./scss/style.scss";
import { getAuthUser } from "./Service/authService";
import Loader from "./Components/UI/Loader/Loader";
import { loginStoreAction } from "./store/store";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const PasswordReset = React.lazy(() =>
  import("./Components/views/PasswordReset/PasswordReset")
);
const App = (props) => {
  const history = useHistory();
  const [loadState, setLoadState] = useState(false);
  const dispatch = useDispatch();
  const mapStateToProps = (state) => {
    return {
      postLoginData: state.loginStore.postLoginData,
    };
  };
  const state = useSelector(mapStateToProps);
  useEffect(() => {
    getAuthUser()
      .then((response) => {
        setLoadState(true);
        const data = {
          token: localStorage.getItem("token"),
          userName: response.data.data.first_name,
          isFirstLogin: response.data.data.first_login === "1" ? true : false,
          role_id: response.data.data.role_id,
        };
        dispatch(loginStoreAction.loadToken(data));
      })
      .catch((error) => {
        setLoadState(true);
        history.push("/");
      });
  }, [dispatch, history]);
  let routes = (
    <Switch>
      <Route
        exact
        path="/password-reset"
        name="Reset Password"
        render={(props) => <PasswordReset {...props} />}
      />
      <Route
        exact
        path="/login"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Route
        exact
        path="/register"
        name="Register Page"
        render={(props) => <Register {...props} />}
      />
      <Route
        exact
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />
      <Route
        exact
        path="/500"
        name="Page 500"
        render={(props) => <Page500 {...props} />}
      />
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  if (state.postLoginData.token) {
    routes = (
      <Switch>
        <Route
          exact
          path="/404"
          name="Page 404"
          render={(props) => <Page404 {...props} />}
        />
        <Route
          exact
          path="/500"
          name="Page 500"
          render={(props) => <Page500 {...props} />}
        />
        <Route
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }

  if (state.postLoginData.token && state.postLoginData.isFirstLogin) {
    routes = (
      <Switch>
        <Route
          exact
          path="/password-reset"
          name="Reset Password"
          render={(props) => <PasswordReset {...props} />}
        />
        <Route exact path="*">
          <Redirect to="/password-reset" />
        </Route>
      </Switch>
    );
  }
  if (!loadState) {
    return <Loader />;
  }
  if (loadState) {
    return <React.Suspense fallback={loading}>{routes}</React.Suspense>;
  }
};

export default App;
/**
 * email: "iamnishanthveema@gmail.com"
email_verified_at: "2021-07-16T09:03:24.000000Z"
first_login: "1"
first_name: "Nishanth"
id: 13
last_name: "Veemarasan"
phone_number: "0402457102"
 */
