import { Badge } from "react-bootstrap";
import ToolTip from "src/Components/UI/ToolTip/ToolTip";
import { clientStoreAction } from "src/store/store";
import CBadge from "src/Components/UI/Badge/CBadge";
export const generateRandomPassword = (length) => {
  const string =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*";

  let password = "";
  for (let i = 0; i < length; i++) {
    password += string.charAt(Math.floor(Math.random() * string.length));
  }
  return password;
};

export const convertKey = (key) => {
  const getKey = key.toLowerCase().split(" ");
  const getkeyId = getKey.join("_");
  return getkeyId;
};

export const getkeyFromList = (array) => {
  return Object.keys(array)[0];
};

export const getConfigKeys = (array) => {
  const keys = array.map((element, i) => {
    return (
      <Badge variant="primary" className="mr-2" key={i}>
        {getkeyFromList(element)}
      </Badge>
    );
  });
  return keys;
};
export const getDate = (value) => {
  if (value) {
    const date = new Date(value);
    const getYear = date.getFullYear();
    const getMonth = date.getMonth() + 1;
    const getDay = date.getDate();

    const constructDate = `${getYear}-${getMonth
      .toString()
      .padStart(2, "0")}-${getDay.toString().padStart(2, "0")}`;
    return constructDate;
  } else {
    return "N/A";
  }
};

export const getBadge = (color, value, history, id, type, dispatch) => {
  const onSubmitHandler = (history, id, type, dispatch) => {
    dispatch(
      clientStoreAction.getFilterParams({
        id,
        type,
      })
    );
    history.push("/filter");
  };
  return (
    <ToolTip value="Click Here">
      <Badge
        variant={color}
        pill={true}
        style={{ fontSize: "100%" }}
        onClick={() => onSubmitHandler(history, id, type, dispatch)}
      >
        {value}
      </Badge>
    </ToolTip>
  );
};

export const getStatus = (value) => {
  switch (value) {
    case "1":
      return <CBadge color="success" size="90%" value="Active" />;
    case "0":
      return <CBadge color="danger" size="90%" value="In Active" />;
    default:
      return <CBadge color="success" size="90%" value="In Active" />;
  }
};
