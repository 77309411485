import { configureStore } from "@reduxjs/toolkit";
import clientSlice from "./client-slice";
import configSlice from "./config-store";
import loginSlice from "./login-store";
import providerSlice from "./provider-slice";
import registerSlice from "./register-slice";
import responsiveSlice from "./responsive-slice";
import tokenSlice from "./user-token-slice";

const store = configureStore({
  reducer: {
    responsiveStore: responsiveSlice.reducer,
    providerStore: providerSlice.reducer,
    registerStore: registerSlice.reducer,
    loginStore: loginSlice.reducer,
    clientStore: clientSlice.reducer,
    configStore: configSlice.reducer,
    tokenStore: tokenSlice.reducer,
  },
});

export const responsiveStoreAction = responsiveSlice.actions;
export const providerStoreAction = providerSlice.actions;
export const registerStoreAction = registerSlice.actions;
export const loginStoreAction = loginSlice.actions;
export const clientStoreAction = clientSlice.actions;
export const configStoreAction = configSlice.actions;
export const tokenStoreAction = tokenSlice.actions;
export default store;
