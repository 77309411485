import API from "src/axios/axios";

export const loginApi = (data) => {
  return API.post("login", data);
};

export const passwordResetApi = (data) => {
  const token = localStorage.getItem("token");
  return API.post("users/reset-password", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getAuthUser = () => {
  const token = localStorage.getItem("token");
  return API.get("users/get", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const logoutApi = () => {
  const token = localStorage.getItem("token");
  return API.get("users/logout", {
    headers: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
};

export const addProviderApi = (data) => {
  return API.post("provider/create", data);
};
export const UpdateProviderApi = (data) => {
  return API.post("provider/update", data);
};
export const getClientsApi = (params, row) => {
  let url = `client/get/${row}`;
  if (params) {
    url = `client/get/${row}?${params}`;
  }
  return API.get(url);
};

export const getFilerApi = (id, type, params) => {
  let url = `client/filter/${id}/${type}`;
  if (params) {
    url = `client/filter/${id}/${type}?${params}`;
  }
  return API.get(url);
};
export const sendLiveRequestApi = (id) => {
  return API.get(`client/update-live-token/${id}`);
};
export const getConfigApi = (param, row) => {
  let url = `config/get/${row}`;
  if (param) {
    url = `config/get/${row}?${param}`;
  }
  return API.get(url);
};
export const getClientNamesApi = () => {
  return API.get(`client/get-client-name`);
};

export const sendConfigFilterApi = (id, type, param) => {
  let url = `config/filter/${id}/${type}`;
  if (param) {
    url = `config/filter/${id}/${type}?${param}`;
  }
  return API.get(url);
};

export const getUserTokensApi = (row, param) => {
  const token = localStorage.getItem("token");
  let url = `users/get-all-tokens/${row}`;
  if (param) {
    url = `users/get-all-tokens/${row}?${param}`;
  }
  return API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getUserConfigApi = (row, param) => {
  const token = localStorage.getItem("token");
  let url = `users/get-all-config/${row}`;
  if (param) {
    url = `users/get-all-config/${row}?${param}`;
  }
  return API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const addTokenApi = (data) => {
  const token = localStorage.getItem("token");
  return API.post("users/generate-token", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const sendUpdateStatusAPI = (data) => {
  return API.post(`config/update-status`, data);
};

export const sendSearchClientAPI = (row, key) => {
  let url = `client/search-client/${row}/${key}`;

  return API.get(url);
};

export const sendClientUrlAPI = (url) => {
  return API.get(url);
};
