import { createSlice } from "@reduxjs/toolkit";
import {
  getClientsApi,
  getFilerApi,
  sendClientUrlAPI,
  sendConfigFilterApi,
  sendLiveRequestApi,
  sendSearchClientAPI,
} from "src/Service/authService";
import { clientStoreAction } from "./store";

const initialState = {
  tableData: {
    data: [],
    isDataReceived: false,
    queryParam: "",
  },
  filterData: {
    data: [],
    isDataReceived: false,
    queryParam: "",
    id: 0,
    type: "",
    isDataChanged: true,
  },
  filterModalData: {
    isModalOpen: false,
    modalHeading: "",
    modalActionButton: "",
    isLoading: false,
    color: "",
    filterId: 0,
    response: false,
    disabled: false,
  },
  message: {
    msg: [],
    color: "",
    status: true,
  },
  searchData: {
    isloading: false,
  },
};

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    getResponse(state, action) {
      state.tableData = {
        ...state.tableData,
        data: action.payload.data,
        isDataReceived: true,
      };
    },
    getFilterParams(state, action) {
      state.filterData = {
        ...state.filterData,
        id: action.payload.id,
        type: action.payload.type,
      };
      //  console.log(state.filterData);
    },
    getFilterResponse(state, action) {
      state.filterData = {
        ...state.filterData,
        data: action.payload.data,
        isDataReceived: true,
      };
    },
    updateParams(state, action) {
      state.filterData = {
        ...state.filterData,
        queryParam: action.payload.param,
      };
      console.log(state.filterData);
    },
    openFilterActionModal(state, action) {
      state.filterModalData = {
        ...state.filterModalData,
        modalHeading: action.payload.heading,
        modalActionButton: action.payload.action,
        color: action.payload.color,
        filterId: action.payload.id,
        isModalOpen: true,
      };
    },
    closeFilterModal(state) {
      state.filterModalData = {
        isModalOpen: false,
        modalHeading: "",
        modalActionButton: "",
        isLoading: false,
        color: "",
        filterId: 0,
        response: false,
        disabled: false,
      };
      state.message = {
        msg: [],
        color: "",
        status: true,
      };
    },
    sendFilterRequest(state, action) {
      state.filterModalData = {
        ...state.filterModalData,
        isLoading: true,
      };
      state.filterData = {
        ...state.filterData,
        isDataChanged: false,
      };
    },
    getActionResponse(state, action) {
      state.filterModalData = {
        ...state.filterModalData,
        isLoading: false,
        response: true,
        disabled: action.payload.disabled,
        filterId: action.payload.id,
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
      };
      state.filterData = {
        ...state.filterData,
        isDataChanged: true,
      };
    },
    sendSearchRequest(state) {
      state.searchData = {
        isloading: true,
      };
    },
    getSearchReeponse(state) {
      state.searchData = {
        isLoading: false,
      };
    },
  },
});

export default clientSlice;

export const getClientsData = (params = "", row) => {
  return (dispatch) => {
    getClientsApi(params, row)
      .then((response) => {
        dispatch(
          clientStoreAction.getResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const searchClient = (searchParam) => {
  return (dispatch) => {
    if (!searchParam) {
      dispatch(getClientsData("", 10));
    } else {
      dispatch(getSearchClientsData(10, searchParam));
    }
  };
};
export const getSearchClientsData = (row, key) => {
  return (dispatch) => {
    dispatch(clientStoreAction.sendSearchRequest());
    sendSearchClientAPI(row, key)
      .then((response) => {
        dispatch(clientStoreAction.getSearchReeponse());
        dispatch(
          clientStoreAction.getResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
export const getClientsDataUrl = (url) => {
  return (dispatch) => {
    sendClientUrlAPI(url)
      .then((response) => {
        dispatch(
          clientStoreAction.getResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};
export const sendFilterRequest = (data) => {
  return (dispatch) => {
    sendConfigFilterApi(data.id, data.type, data.params)
      .then((response) => {
        console.log(data.id, data.type, data.params);
        dispatch(
          clientStoreAction.getFilterResponse({
            data: response.data.data,
          })
        );
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

export const sendActionRequest = (data) => {
  return (dispatch) => {
    sendLiveRequestApi(data.id)
      .then((response) => {
        console.log(response.data.data.msg);
      })
      .catch((error) => console.log(error.response));
  };
};

export const sendFRequest = (data) => {
  return (dispatch) => {
    dispatch(clientStoreAction.sendFilterRequest());
    sendLiveRequestApi(data.id)
      .then((response) => {
        dispatch(
          clientStoreAction.getActionResponse({
            msg: [response.data.data.msg],
            color: "success",
            disabled: true,
            id: 0,
          })
        );
      })
      .catch((error) => {
        dispatch(
          clientStoreAction.getActionResponse({
            msg: [error.response.data.error],
            color: "danger",
          })
        );
      });
  };
};
