import axios from "axios";
const API = axios.create({
  baseURL: "https://wp.cartdna.com/app/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
});

export default API;

//http://carttest.test/public/api/
//https://wp.cartdna.com/app/api/
