import { createSlice } from "@reduxjs/toolkit";
import { generateRandomPassword } from "src/Service/appService";
import { loginApi, passwordResetApi } from "src/Service/authService";
import { loginStoreAction } from "./store";
const initialState = {
  isLoading: false,
  message: {
    msg: [],
    color: "",
    status: false,
    isLoggedIn: false,
  },

  postLoginData: {
    userName: "",
    token: null,
    isFirstLogin: false,
    role_id: 0,
  },
  redirect: null,
  passwordResetModalData: {
    password: "",
    isModalOpen: false,
    isButtonLoading: false,
    isModalLoading: false,
  },

  resetPasswordData: {
    isLoading: false,
    redirectUser: false,
    msg: [],
    color: "",
    status: false,
    response: false,
  },
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateToken(state, action) {
      state.postLoginData = {
        ...state.postLoginData,
        token: action.payload.token,
        role_id: action.payload.role_id,
      };
      //  console.log(state.postLoginData);
    },
    loadingData(state) {
      state.isLoading = true;
      state.message = {
        ...state.message,
        msg: ["Authenticating...."],
        isLoggedIn: true,
        color: "success",
        status: true,
      };
    },
    loadingInitialPostData(state) {
      state.postLoginData = {
        ...state.postLoginData,
        isLoading: true,
      };
    },
    loggedInData(state, action) {
      state.isLoading = false;
      state.message = {
        ...state.message,
        msg: action.payload.data,
        status: action.payload.status,
        isLoggedIn: true,
        color: action.payload.color,
      };
      state.postLoginData = {
        userName: action.payload.name,
        // token: action.payload.token,
        isFirstLogin: action.payload.firstLogin,
      };

      state.redirect = action.payload.redirect;
    },
    missingData(state, action) {
      state.isLoading = false;
      state.message = {
        ...state.message,
        msg: action.payload.data,
        status: action.payload.status,
        isLoggedIn: true,
        color: action.payload.color,
      };
    },
    closeAlertBox(state) {
      state.message = {
        ...state.message,
        isLoggedIn: false,
      };
    },
    loadPasswordResetButton(state) {
      state.passwordResetModalData = {
        ...state.passwordResetModalData,
        isButtonLoading: true,
      };
    },
    openResetModal(state, action) {
      state.passwordResetModalData = {
        ...state.passwordResetModalData,
        isButtonLoading: false,
        password: action.payload.password,
        isModalOpen: true,
      };
    },
    sendNewPasswordRequest(state) {
      state.passwordResetModalData = {
        ...state.passwordResetModalData,
        isModalLoading: true,
      };
    },
    setNewPassword(state, action) {
      state.passwordResetModalData = {
        ...state.passwordResetModalData,
        isModalLoading: false,
        password: action.payload.password,
      };
    },
    closeResetModal(state) {
      state.passwordResetModalData = {
        password: "",
        isModalOpen: false,
        isButtonLoading: false,
        isModalLoading: false,
      };
    },
    sendPasswordChangeRequest(state) {
      state.resetPasswordData = {
        ...state.resetPasswordData,
        isLoading: true,
      };
    },
    loadToken(state, action) {
      state.postLoginData = {
        userName: action.payload.userName,
        token: action.payload.token,
        isFirstLogin: action.payload.isFirstLogin,
        role_id: action.payload.role_id,
      };
      //  console.log(state.postLoginData);
    },

    passwordResetted(state, action) {
      state.resetPasswordData = {
        isLoading: false,
        msg: action.payload.msg,
        color: action.payload.color,
        status: action.payload.status,
        response: true,
      };
    },
    redirectAfterReset(state) {
      state.postLoginData = {
        ...state.postLoginData,
        isFirstLogin: false,
      };
    },
    logout(state) {
      state.postLoginData = {
        userName: "",
        token: null,
        isFirstLogin: false,
      };
      state.message = {
        msg: [],
        color: "",
        status: false,
        isLoggedIn: false,
      };
    },
  },
});

export default loginSlice;

export const LoginData = (data, history) => {
  return async (dispatch) => {
    dispatch(loginStoreAction.loadingData());
    loginApi(data)
      .then((response) => {
        console.log(response.data);
        const array = ["login successfull.. Rediracting to Admin Panel!!"];
        localStorage.setItem("token", response.data.data.token);
        let redirect = "/password-reset";
        console.log(response.data.data.first_login);
        if (response.data.data.first_login === "0") {
          redirect = "/";
          console.log("hi");
        }
        console.log("hil");
        dispatch(
          loginStoreAction.loggedInData({
            data: array,
            status: true,
            color: "success",
            //token: response.data.data.token,
            firstLogin: response.data.data.first_login === "1" ? true : false,
            name: response.data.data.first_name,
            redirect,
          })
        );
        setTimeout(() => {
          dispatch(
            loginStoreAction.updateToken({
              token: response.data.data.token,
              role_id: response.data.data.role_id,
            })
          );
        }, 1000);
      })
      .catch((error) => {
        // console.log(error);
        const array = [error.response.data.error];
        dispatch(
          loginStoreAction.loggedInData({
            data: array,
            status: true,
            color: "danger",
          })
        );
      });
  };
};

export const missingLoginData = (data) => {
  return (dispatch) => {
    const array = ["some Input fields are missing!!"];
    dispatch(
      loginStoreAction.missingData({
        data: array,
        status: true,
        color: "danger",
      })
    );
  };
};

export const openPasswordResetModal = () => {
  return (dispatch) => {
    dispatch(loginStoreAction.loadPasswordResetButton());
    const password = generateRandomPassword(15);
    setTimeout(() => {
      dispatch(loginStoreAction.openResetModal({ password }));
    }, 500);
  };
};

export const generateNewPassword = () => {
  return (dispatch) => {
    dispatch(loginStoreAction.sendNewPasswordRequest());
    const password = generateRandomPassword(15);
    setTimeout(() => {
      dispatch(loginStoreAction.setNewPassword({ password }));
    }, 500);
  };
};
export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch(loginStoreAction.logout());
    // logoutApi()
    //   .then((response) => {
    //     localStorage.removeItem("token");
    //     dispatch(loginStoreAction.logout());
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  };
};

export const resetPassword = (data, history) => {
  return (dispatch) => {
    dispatch(loginStoreAction.sendPasswordChangeRequest());
    passwordResetApi(data)
      .then((response) => {
        //   console.log(response.data);
        const array = ["Password Updated!! Redirecting to Admin Panel"];
        dispatch(
          loginStoreAction.passwordResetted({
            msg: array,
            color: "success",
            status: true,
          })
        );
        setTimeout(() => {
          dispatch(loginStoreAction.redirectAfterReset({}));
          history.push("/");
        }, 500);
      })
      .catch((error) => {
        const array = [error.response.data.error.password[0]];
        dispatch(
          loginStoreAction.passwordResetted({
            msg: array,
            color: "danger",
            status: true,
          })
        );
      });
  };
};
