import { createSlice } from "@reduxjs/toolkit";
import {
  getClientNamesApi,
  getConfigApi,
  sendConfigFilterApi,
  sendLiveRequestApi,
  sendUpdateStatusAPI,
} from "src/Service/authService";
import { configStoreAction } from "./store";

const initialState = {
  configData: {
    isDataReceived: false,
    data: [],
    names: [],
  },
  reRunComponent: {
    isDataChanged: true,
    queryParam: "",
  },
  modalData: {
    isModalOpen: false,
    modalHeading: "",
    modalActionButton: "",
    isLoading: false,
    color: "",
    filterId: 0,
    response: false,
    disabled: false,
    data: "",
  },
  actionModalData: {
    isModalOpen: false,
    modalHeading: "",
    modalActionButton: "",
    isLoading: false,
    color: "",
    filterId: 0,
    response: false,
    disabled: false,
    data: "",
  },
  message: {
    msg: [],
    color: "",
    status: true,
  },
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    getConfigData(state, action) {
      state.configData = {
        ...state.configData,
        isDataReceived: true,
        data: action.payload.data,
      };
    },
    updateparam(state, action) {
      state.reRunComponent = {
        ...state.reRunComponent,
        queryParam: action.payload.data,
      };
    },
    updateClientName(state, action) {
      state.configData = {
        ...state.configData,
        names: action.payload.data,
      };
    },
    sendFilterRequest(state) {
      state.configData = {
        ...state.configData,
        isDataReceived: false,
      };
    },
    openModal(state, action) {
      state.modalData = {
        ...state.modalData,
        modalHeading: action.payload.heading,
        modalActionButton: action.payload.action,
        color: action.payload.color,
        filterId: action.payload.id,
        isModalOpen: true,
      };
    },
    openStatusModal(state, action) {
      state.actionModalData = {
        ...state.modalData,
        modalHeading: action.payload.heading,
        modalActionButton: action.payload.action,
        color: action.payload.color,
        isModalOpen: true,
        data: action.payload.data,
      };
      //  console.log(state.actionModalData);
    },
    sendUpdateRequest(state) {
      state.modalData = {
        ...state.modalData,
        isLoading: true,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: false,
      };
    },
    sendStatusUpdateRequest(state) {
      state.actionModalData = {
        ...state.actionModalData,
        isLoading: true,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: false,
      };
    },
    getUpdateResponse(state, action) {
      state.modalData = {
        ...state.modalData,
        isLoading: false,
        response: true,
        disabled: action.payload.disabled,
        filterId: action.payload.id,
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: true,
      };
    },
    getUpdateStatusResponse(state, action) {
      state.actionModalData = {
        ...state.actionModalData,
        isLoading: false,
        response: true,
        disabled: action.payload.disabled,
        data: action.payload.data,
      };
      state.message = {
        ...state.message,
        msg: action.payload.msg,
        color: action.payload.color,
      };
      state.reRunComponent = {
        ...state.reRunComponent,
        isDataChanged: true,
      };
    },
    closeModal(state) {
      state.modalData = {
        isModalOpen: false,
        modalHeading: "",
        modalActionButton: "",
        isLoading: false,
        color: "",
        filterId: 0,
        response: false,
        disabled: false,
      };
      state.actionModalData = {
        isModalOpen: false,
        modalHeading: "",
        modalActionButton: "",
        isLoading: false,
        color: "",
        filterId: 0,
        response: false,
        disabled: false,
        data: "",
      };
      state.message = {
        msg: [],
        color: "",
        status: true,
      };
    },
  },
});
export default configSlice;

export const getConfigData = (data) => {
  return (dispatch) => {
    getConfigApi(data.param, data.row)
      .then((response) => {
        dispatch(
          configStoreAction.getConfigData({
            data: response.data.data,
          })
        );
      })
      .catch((error) => console.log(error.response));
  };
};
export const getClientsName = () => {
  return (dispatch) => {
    getClientNamesApi()
      .then((response) => {
        console.log(response.data);
        dispatch(
          configStoreAction.updateClientName({
            data: response.data.data,
          })
        );
      })
      .catch((error) => console.log(error.response));
  };
};

export const getFilterConfig = (id, type, param) => {
  return (dispatch) => {
    dispatch(configStoreAction.sendFilterRequest());
    //  console.log("two", id, type, param);
    sendConfigFilterApi(id, type, param)
      .then((response) => {
        dispatch(
          configStoreAction.getConfigData({
            data: response.data.data,
          })
        );
      })
      .catch((error) => console.log(error.response));
  };
};

export const sendActionRequest = (data) => {
  return (dispatch) => {
    console.log(data.id);
    dispatch(configStoreAction.sendUpdateRequest());
    sendLiveRequestApi(data.id)
      .then((response) => {
        dispatch(
          configStoreAction.getUpdateResponse({
            msg: [response.data.data.msg],
            color: "success",
            disabled: true,
            id: 0,
          })
        );
      })
      .catch((error) => {
        dispatch(
          configStoreAction.getUpdateResponse({
            msg: [error.response.data.error],
            color: "danger",
          })
        );
      });
  };
};

export const updateConfigStatus = (data) => {
  return (dispatch) => {
    console.log(data);
    dispatch(configStoreAction.sendStatusUpdateRequest());
    sendUpdateStatusAPI(data)
      .then((response) => {
        console.log(response.data);
        dispatch(
          configStoreAction.getUpdateStatusResponse({
            msg: [response.data.data.msg],
            color: "success",
            disabled: true,
            data: "",
          })
        );
        setTimeout(() => {
          dispatch(configStoreAction.closeModal());
        }, 600);
      })
      .catch((error) => {
        dispatch(
          configStoreAction.getUpdateStatusResponse({
            msg: [error.response.data.error],
            color: "danger",
            data,
          })
        );
      });
  };
};
